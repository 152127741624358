<template>
  <base-section id="soon">
    <base-heading
      :title="$t('sections.soon.title')"
      class="primary--text"
    />

    <base-subheading
      class="primary--text"
      :title="$t('sections.soon.subheading1')"
    />

    <base-subheading
      space="8"
      :title="$t('sections.soon.subheading2')"
    />

    <base-img
      :src="require('@/assets/paydone/check-dark.png')"
      class="mx-auto mb-12"
      contain
      height="100"
      width="250"
    />

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        {{ $t("sections.soon.btn") }}
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'Soon',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
